import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import Split_1 from "./Components/Pages/Splits/Split_1";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Questionnaire from "./Components/Pages/Questionnaire";
import Followup from "./Components/Pages/Splits/Followup";
import Preview from "./Components/Pages/Preview";
import OpenBankingProceed from "./Components/Pages/OpenBankingProceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import Thankyou from "./Components/Pages/Thankyou";
import IdUpload from "./Components/Pages/IdUpload"
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";
import Proceed from "./Components/Pages/Proceed";
import PdfView from"./Components/Pages/PdfView";
import AD_HDR_V1 from"./Components/Pages/Splits/AD_HDR_V1";
import AD_HDR_V2 from "./Components/Pages/Splits/AD_HDR_V2";
import ThankyouPage from "./Components/Pages/ThankyouPage";
import OtpPage_AD_HDR_V2 from "./Components/Pages/Splits/OtpPage_AD_HDR_V2";
import Upload_doc_AD_HDR_V2 from "./Components/Pages/Upload_doc_AD_HDR_V2";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/Split_1"]} component={Split_1} />
              <Route exact path={["/AD_HDR_V1"]} component={AD_HDR_V1} />
              <Route exact path={"/questionnaire"} component={Questionnaire} />
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/thankyoupage"} component={ThankyouPage} />
              <Route exact path={"/buds-redirect-page"} component={BudsRedirectPage} />
              <Route exact path={["/id-upload"]} component={IdUpload} />
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/ADV_1"]} component={ADV_1} />
              <Route exact path={["/ADV1/contact"]} component={ADV1_Contact}/>
              <Route exact path={"/proceed-open-banking"} component={OpenBankingProceed} />
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>
              <Route exact path={["/AD_HDR_V2"]} component={AD_HDR_V2} />
              <Route exact path={["/google","/testing","/index"]} component={AdtopiaLoadDynamic}/>

              {/* <Route exact path={["/otp_page"]} component={OtpPage_AD_HDR_V2} /> */}
              {/* <Route exact path={["/upload_doc"]} component={Upload_doc_AD_HDR_V2} /> */}
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
