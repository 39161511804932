import React, { useState } from 'react'
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from '../../../Utility/FbViewPixel';
import bigstock2p1 from '../../../assets/img/AD_HDR_V2/bigstock2p1.jpg';
import bigstock3 from '../../../assets/img/AD_HDR_V2/bigstock3.jpg'
import loader_review2 from '../../../assets/img/AD_HDR_V2/loader-review2.gif'
import Header from '../../Includes/Layouts/AD_HDR_V1/Header'
import Footer from '../../Includes/Layouts/AD_HDR_V2/Footer'
import FormAD_HDR_V2 from '../../Forms/FormAD_HDR_V2'
import SimpleModal from '../../Includes/Layouts/AD_HDR_V2/SimpleModal'
import LoadingModal from '../../Includes/Layouts/AD_HDR_V2/LoadingModal'
import InfoModal from '../../Includes/Layouts/AD_HDR_V2/InfoModal'
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const AD_HDR_V2 = () => {
  const [showModal, setShowModal] = useState("hide");
  const [modalBody, setModalBody] = useState("");
  const [showLoadingModal, setLoadingModal] = useState(false);
  const [infoModalType, setInfoModalType] = useState("");

  const handleShowModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("hide");
  };

  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('AD_HDR_V2', 'AD_HDR_V2');
  // console.log("i am here" + DynamicRouteNextPage);
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="AD_HDR_V2" />
      <FbViewPixel />
      <div className="housing-disrepair-2" id="housing-disrepair-2">
        {/* ____________________HEADER -  SECTION____________________ */}
        <Header />
        {/* ____________________form  part - FORM SECTION____________________ */}
        <FormAD_HDR_V2
          handleShowModal={handleShowModal}
          setModalBody={setModalBody}
          setLoadingModal={setLoadingModal}
          setInfoModalType={setInfoModalType}
          splitName={DynamicRouteSplitName}
          nextPage={DynamicRouteNextPage}
        />
        {/* ____________________content_1  - SECTION____________________ */}
        <section className="content_1">
          <div className="container">
            <div className="row">
              <div className=" col-md-6 col-12 d-flex justify-content-center align-items-center">
                <img className="" src={bigstock2p1} alt="" />
              </div>
              <div className="col-md-6 col-12 px-2 pt-3">
                <h3 className="text-center text-md-start"> What can I claim for?</h3>
                <p className="text-center text-md-start">

                  You could be eligible to claim for housing disrepair if your home
                  has any of the below issues:
                </p>
                <ul>
                  <li> Rising damp, leading to mould </li>
                  <li> Penetrating damp, leading to mould</li>
                  <li> Non-functioning or unsafe heating system or boiler</li>
                  <li> Unsafe flooring or staircases </li>
                  <li> Rotten doors or window frames </li>
                  <li> Leaking pipes or cracked sanitation equipment </li>
                  <li> Faulty electrics </li>
                  <li> Loose tiles or brickwork </li>
                  <li> Vermin or insect infestation </li>
                </ul>
                <p>
                  Is your property full of damp? Perhaps there’s unsafe flooring or
                  faulty electrics. You could be eligible to make a claim to help get
                  the repairs done and potentially get some compensation too. Find out
                  how to start your housing disrepair claim.
                </p>
                <p>
                  You may also make a compensation claim for personal injury or an
                  equality act claim if the housing disrepair has caused you physical
                  or mental health issues, discrimination or has directly put you or
                  anyone in the household at risk of harm, including high and
                  sustained levels of stress and suffering. Find out more about
                  Equality Act claims.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* ____________________content_2  - SECTION____________________ */}
        <section className="content_2 ">
          <div className="container">
            <div className="row col-reverse">
              <div className=" col-md-6 col-12 px-2">
                <h3>Am I eligible to claim?</h3>
                <p>

                  Eligibility to make a housing disrepair claim depends on your
                  individual situation. We have set criteria you need to be eligible
                  for a claim. These can include:
                </p>
                <ul>
                  <li> Whether you live in a house or a flat</li>
                  <li>

                    Whether you rent your home through social housing or privately
                  </li>
                  <li> The seriousness of the disrepair</li>
                  <li>

                    The impact of the disrepair on your home and the daily lives of
                    those living there
                  </li>
                  <li>

                    The length of time the property has been in a state of disrepair
                  </li>
                  <li>

                    How many times you have reported the repairs needed to your
                    housing association or council landlord
                  </li>
                  <li>

                    The steps your landlord has or has not taken to repair your
                    property
                  </li>
                </ul>
                <p className='p_15'>
                  Understanding what housing disrepair is and if you’re eligible to
                  claim can be tricky. So, we created this quick and easy guide to
                  help explain it.
                </p>
                <div className="btn_div">
                  <a className="btn_1" href="#form-part">
                    CHECK NOW
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-12 d-flex justify-content-center align-items-center ">
                <img className="img-fluid" src={bigstock3} alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* ____________________Footer  - SECTION____________________ */}
        <Footer
          setInfoModalType={setInfoModalType}
        />
        {/* ____________________unqlifiedmodal-pop - MODAL____________________ */}
        <div
          className="modal fade  unqlifiedmodal-pop"
          tabIndex={-1}
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content bor-b5">
              <div className="modal-header noborder" />
              <div className="modal-body">
                <p className="unqualify-modal-p">
                  Sorry, if you have not complained to your landlord about these
                  issues then we will not be able to assist you.
                </p>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ____________________not_qualify_modal - MODAL____________________ */}
        <SimpleModal
          show={showModal}
          // onHide={handleCloseModal}
          // title="Modal Title"
          modalBody={modalBody}
          handleClose={handleCloseModal}
        />
        <LoadingModal
          showLoadingModal={showLoadingModal}
          setLoadingModal={setLoadingModal}
        />
        <InfoModal
          infoModalType={infoModalType}
          setInfoModalType={setInfoModalType}
        />
        <div
          className="modal fade "
          id="not_qualify_modal"
          tabIndex={-1}
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content bor-b5">
              <div className="modal-header noborder" />
              <div className="modal-body">
                <p className="unqualify-modal-p">
                  Unfortunately, it appears based on this answer you do not qualify
                  for compensation.
                </p>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ____________________unqlifiedmodal-pop3 - MODAL____________________ */}
        <div
          className="modal fade  unqlifiedmodal-pop3"
          tabIndex={-1}
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content bor-b5">
              <div className="modal-header noborder" />
              <div className="modal-body">
                <p className="unqualify-modal-p">
                  Sorry, if you owe more than £1,500 then we will not be able to
                  assist you.
                </p>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ____________________unqlifiedmodal-pop4 - MODAL____________________ */}
        <div
          className="modal fade "
          id="unqlifiedmodal-pop4"
          tabIndex={-1}
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content bor-b5">
              <div className="modal-header noborder" />
              <div className="modal-body">
                <p className="unqualify-modal-p">
                  Sorry, if you do not reside in England or Wales, we will not be able
                  to assist you.
                </p>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ____________________id=analyzemodal- MODAL____________________ */}
        <div
          className="modal modal_1 fade "
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
          id="analyzemodal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div id="slide6k" className="col-12  text-center">
                  <img src={loader_review2} alt="" />
                  <h3 id="h3_first">Loading your responses</h3>
                  <div id="h3_second" style={{ display: "none" }}>
                    <h3> Great News! </h3>
                    <h4>
                      We have all of the information we need to work on getting you
                      compensated!
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ____________________id=Privacy- MODAL____________________ */}
        <div
          className="modal fade"
          id="Privacy"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="privacyModalLabel"
          aria-modal="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="privacyModalLabel">
                  Privacy Policy
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >

                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <ul>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                </ul>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ____________________id=Privacy- MODAL____________________ */}
        <div
          className="modal fade"
          id="terms_modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="privacyModalLabel"
          aria-modal="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="privacyModalLabel">
                  Terms Policy
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >

                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <ul>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                  <li>Lorem ipsum dolor sit amet, consectetur </li>
                </ul>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam. .
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                  dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                  pretium, tincidunt diam.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AD_HDR_V2