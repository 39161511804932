import React, { useEffect, useReducer, useState, useRef, useContext } from "react";
import {useForm} from "react-hook-form";
import { useHistory } from "react-router-dom";
import bigstock2p from "../../assets/img/AD_HDR_V1/bigstock2p.jpg";
import QuestionRadioSlide from "../Includes/Layouts/Questionnaire/QuestionRadioSlide";
import QuestionCheckBox from "../Includes/Layouts/Questionnaire/QuestionCheckBox";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import PersonalDetails from "../Includes/Layouts/Split_1/PersonalDetails";
import {
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5,
    questinnare6,
    questinnare7,
    questinnare8,
    questinnare9,
    questinnare10,
    questinnare11,
    questinnare12
} from "../../Constants/Question_AD_HDR_V1";
import QuestionPersonalSlide from "../Includes/Layouts/Questionnaire/QuestionPersonalSlide";
import PostCode_AD_HDR_V1 from "../Includes/Layouts/Split_1/AD_HDR_V1/PostCode_AD_HDR_V1";
import PostCode from "../Includes/Layouts/Split_1/PostCode";
import LoadingModal from "../Includes/Layouts/AD_HDR_V2/LoadingModal";
import GTMDataLayer from  "../Includes/Layouts/GTMDataLayer";
const FormAD_HDR_V1 = (props) => {

    const initialState = {
        showQuestionSlide1: "d-block",
        showQuestionSlide2: "d-none",
        showQuestionSlide3: "d-none",
        showQuestionSlide4: "d-none",
        showQuestionSlide5: "d-none",
        showQuestionSlide6: "d-none",
        showQuestionSlide7: "d-none",
        showQuestionSlide8: "d-none",
        showQuestionSlide9: "d-none",
        showQuestionSlide10: "d-none",
        showQuestionSlide11: "d-none",
        showQuestionSlide12: "d-none",
    };
    
    const QuestionReducer = (state, action) => {
        switch (action.type) {
            case "nextQuestionSlide": {
                console.log(action.payload.clickedSlide.slide);
                if (action.payload.clickedSlide.slide == "question_1") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-none",
                        showQuestionSlide11: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_11") {
                    return {
                        ...state,
                        showQuestionSlide11: "d-none",
                        showQuestionSlide9: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_12") {
                    return {
                        ...state,
                        showQuestionSlide12: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_2") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide3: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_3") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide4: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_4") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        showQuestionSlide5: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_5") {
                    return {
                        ...state,
                        showQuestionSlide5: "d-none",
                        showQuestionSlide6: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_6") {
                    return {
                        ...state,
                        showQuestionSlide6: "d-none",
                        showQuestionSlide7: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_7") {
                    return {
                        ...state,
                        showQuestionSlide7: "d-none",
                        showQuestionSlide8: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_8") {
                    return {
                        ...state,
                        showQuestionSlide8: "d-none",
                        showQuestionSlide12: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_9") {
                    return {
                        ...state,
                        showQuestionSlide9: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                }else if (action.payload.clickedSlide.slide == "question_10") {
                    return {
                        ...state,
                        showQuestionSlide10: "d-block",
                        showQuestionSlide12: "d-none",
                    };
                } 
                
                else {
                    return {
                        ...state,
                    };
                }
            }
            case "backQuestionSlide": {
                console.log(action.payload.prevSlide.slide);
                if (action.payload.prevSlide.slide == "back11") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-block",
                        showQuestionSlide11: "d-none",
                    };
                } else if (action.payload.prevSlide.slide == "back9") {
                    return {
                        ...state,
                        showQuestionSlide9: "d-none",
                        showQuestionSlide11: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back2") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide9: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back3") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back4") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        showQuestionSlide3: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back5") {
                    return {
                        ...state,
                        showQuestionSlide5: "d-none",
                        showQuestionSlide4: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back6") {
                    return {
                        ...state,
                        showQuestionSlide6: "d-none",
                        showQuestionSlide5: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back7") {
                    return {
                        ...state,
                        showQuestionSlide7: "d-none",
                        showQuestionSlide6: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back8") {
                    return {
                        ...state,
                        showQuestionSlide8: "d-none",
                        showQuestionSlide7: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back7n") {
                    return {
                        ...state,
                        showQuestionSlide12: "d-none",
                        showQuestionSlide8: "d-block",
                    };
                } else if (action.payload.prevSlide.slide == "back10") {
                    return {
                    // document.getElementById('next08n').style.opacity = "1";
                        ...state,
                        showQuestionSlide10: "d-none",
                        showQuestionSlide9: "d-block",
                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
        }
    };
    const [state, dispatch] = useReducer(QuestionReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState();
    const [stage, setStage] = useState(1)
    const [prevSlide, setPrevSlide] = useState();
    const splitForm = useRef(null);
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { queryString } = useContext(QueryStringContext);
    const history = useHistory();
    const field = "pid";
    const scrollfrmv2div = useRef([]);
    const { saveDataIngestion, isLoading } = useDataIngestion();
    const [showLoadingModal, setLoadingModal] = useState("hide");
    useEffect(() => {
		if (clickedSlide) {
			dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
		}
	}, [clickedSlide]);

	useEffect(() => {
		if (prevSlide) {
			dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
		}
	}, [prevSlide]);
    
    const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
	const slideChange = (e, radioName) => {
    gtmDataLayer(radioName, e.target);
    window.scrollTo(0, 300);
       console.log(radioName);
		setStage(stage + 1);
		setClickedSlide({ slide: radioName });
	}
	const previousSlide = (e) => {
    window.scrollTo(0, 300);
		var previous_slide = e.target.id;
		setStage(stage - 1);
		setPrevSlide({ slide: previous_slide })
	}
    const slideChangeAddress = () => {
        setTimeout(() => {
          scrollfrmv2div.current.scrollIntoView({
            behavior: "smooth",
            top: 0
          });
        }, 50);
        setClickedSlide({ slide: "address" });
      };

    const gtmDataLayer = (current_question, element = null) => {
        let question = "";
        let answer = "";
        switch (current_question) {
            case "next":
                question = "Start";
                break;
            case "question_1":
                question = "Property Ownership";
                break;
            case "question_11":
                question = "Repairs to complete";
                break;
            case "question_9":
                question = "Claimed Previosly";
                break;
            case "question_2":
                question = "Rent From";
                break;
            case "question_3":
                question = "Still living in property";
                break;
            case "question_4":
                question = "Problems of property";
                break;
            case "question_5":
                question = "Complained to landlord";
                break;
            case "question_6":
                question = "addressed the issue";
                break;
            case "question_7":
                question = "behind rent";
                break;
            case "question_8":
                question = "where do you live";
                break;
            case "question_10":
                question = "Contact details";
                break;
        }
        GTMDataLayer({
            question: question,
            answer: answer,
        });
    };
      
      const modalShow = (e) => {
        let getQuestion_8 = splitForm.current;
        console.log(getQuestion_8['question_8'].value)
        if(getQuestion_8['question_8'].value == '' || getQuestion_8['question_8'].value == 25){   
        document.getElementById("quest_8_err").classList.remove('hide');
        document.getElementById("quest_8_err").classList.add('show');
        return false;
        }
        setLoadingModal("show");
        slideChange(e,"question_8");   
      };
    const formSubmit = async () => {
        const form = splitForm.current;
        const values = getValues();
        const formData = values;  
        console.log(formData);
        if (formData.address1 !== "") {
          const txtUdprn = form["txtUdprn"].value;
          const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
          const txtPz_mailsort = form["txtPz_mailsort"].value;
          const txtStreet = form["txtStreet"].value;
          formData.txtUdprn = txtUdprn;
          formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
          formData.txtPz_mailsort = txtPz_mailsort;
          formData.txtStreet = txtStreet;
        }
        formData.page_name = 'AD_HDR_V1';
        formData.pCode_manual = form['pCode_EntryType'].value;
        if (
          visitorParameters.data !== null ||
          visitorParameters.data !== undefined
        ) {
          localStorage.setItem('formData', JSON.stringify(formData));
          localStorage.setItem('queryString',queryString );
          const formSUbmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            'split_form_submit',
            'AD_HDR_V1',
            queryString,
            visitorParameters.data
          )
          if (formSUbmitResult.data.status === "Success") {
    
            if (queryString != null) {
              console.log("first if");
              console.log(queryString);
              if (queryString.indexOf("&" + field + "=") !== -1) {
                console.log("s");
                if(EnvConstants.AppConversionPixel=== 'true'){
                  console.log("if fb");
                  history.push("/fbpixel?split_name=" + props.splitName +"&next_page=" + props.nextPage ); // fb fixel firing
                }else{
                  console.log("else fb");
                  window.scrollTo(0, 0);
                  history.push(
                    "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
                  );
                } 
              } else {
                console.log("another else fb");
                window.scrollTo(0, 0);
                history.push(
                  "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
                );
              }
            } else {
    
              console.log("laset else  fb");
              window.scrollTo(0, 0);
              history.push(
                "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
              );
            }
          }
        }
      };
    return (
        <>
            <section className="form-part" id="form-part">
                <div className="container nopad">
                    <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
                        <form   ref={splitForm}
                            name="split_form"
                            id="user_form"
                            method="POST"
                            autoComplete="off">
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide1}
                                    questionnaire={questinnare1}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="0"
                                    getValues={getValues}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                 <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide11}
                                    questionnaire={questinnare11}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="10"
                                    getValues={getValues}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide9}
                                    questionnaire={questinnare9}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="20"
                                    getValues={getValues}
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide2}
                                    questionnaire={questinnare2}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="30"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                                <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide3}
                                    questionnaire={questinnare3}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="40"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                            {/* ____________________slide3n -start____________________ */}
                                <QuestionCheckBox
                                    showQuestionSlide={state.showQuestionSlide4}
                                    questionnaire={questinnare4}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="50"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />


                            {/* ____________________slide4n -start____________________ */}
                            <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide5}
                                    questionnaire={questinnare5}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="60"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                            {/* ____________________slide5n-start____________________ */}
                            <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide6}
                                    questionnaire={questinnare6}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="70"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />
                            {/* ____________________slide6n-start____________________ */}
                            <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide7}
                                    questionnaire={questinnare7}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    backBtn={false}
                                    value="75"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />  
                            {/* ____________________slide7n-start____________________ */}
                            <QuestionRadioSlide
                                    showQuestionSlide={state.showQuestionSlide8}
                                    questionnaire={questinnare8}
                                    previousSlide={previousSlide}
                                    slideChange={slideChange}
                                    modalShow={modalShow}
                                    showLoadingModalForNextPage={true}
                                    setLoadingModal={props.setLoadingModal}
                                    backBtn={false}
                                    value="80"
                                    validation={register}
                                    validationMsg={errors}
                                    trigger={trigger}
                                />  

                            {/* ____________________slide8n-start____________________ */}
                            {/* <QuestionPersonalSlide
                            showQuestionSlide={state.showQuestionSlide9}
                            slideChange={slideChange}
                            previousSlide={previousSlide}
                            backBtn={false}
                            value="80"
                            /> */}
                            <PersonalDetails
                            className={state.showQuestionSlide12}
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            clearErrors={clearErrors}
                            getValues={getValues}
                            splitForm={splitForm}
                            slideChange={slideChange}
                            slideChangeAddress={slideChangeAddress}
                            backClick={previousSlide}
                            setError={setError}
                            />
                            {/* ____________________slide9n-start____________________ */}
                            <PostCode
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            clearErrors={clearErrors}
                            setError={setError}
                            className={state.showQuestionSlide10}
                            getValues={getValues}
                            splitForm={splitForm}
                            slideChange={slideChange}
                            formSubmit={formSubmit}
                            />

                          {/* <PostCode_AD_HDR_V1
                          showQuestionSlide={state.showQuestionSlide10}
                          value="80"
                          /> */}
                        </form>
                    </div>
                </div>
            </section>
            {/* -----------------modal----------- */}
            <LoadingModal
                showLoadingModal={showLoadingModal}
                setLoadingModal={setLoadingModal}
                />
            {/* ____________________content_1  - SECTION____________________ */}
            <section className="content_1">
                <div className="container">
                    <div className="row">
                        <div className=" col-md-6 col-12 d-flex justify-content-center align-items-center">
                            <img className="" src={bigstock2p} alt="" />
                        </div>
                        <div className=" col-md-6 col-12 px-2 pt-3">
                            <h3 className="text-center text-md-start"> What can I claim for?</h3>
                            <p className=" text-center text-md-start">
                                {" "}
                                You could be eligible to claim for housing disrepair if your home
                                has any of the below issues:
                            </p>
                            <ul>
                                <li> Rising damp, leading to mould </li>
                                <li> Penetrating damp, leading to mould</li>
                                <li> Non-functioning or unsafe heating system or boiler</li>
                                <li> Unsafe flooring or staircases </li>
                                <li> Rotten doors or window frames </li>
                                <li> Leaking pipes or cracked sanitation equipment </li>
                                <li> Faulty electrics </li>
                                <li> Loose tiles or brickwork </li>
                                <li> Vermin or insect infestation </li>
                            </ul>
                            <p>
                                Is your property full of damp? Perhaps there’s unsafe flooring or
                                faulty electrics. You could be eligible to make a claim to help get
                                the repairs done and potentially get some compensation too. Find out
                                how to start your housing disrepair claim.
                            </p>
                            <p>
                                You may also make a compensation claim for personal injury or an
                                equality act claim if the housing disrepair has caused you physical
                                or mental health issues, discrimination or has directly put you or
                                anyone in the household at risk of harm, including high and
                                sustained levels of stress and suffering. Find out more about
                                Equality Act claims.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default FormAD_HDR_V1;