import React, { useState } from "react";
import { ModalQuestion } from "../../../../Constants/ModalConstants";
const UnqualifiedModal = (props) => {
    console.log(ModalQuestion);
    console.log(props.content);
    console.log(ModalQuestion[0][props.content])
    const [modal, setModal] = useState(false);
    return (
        <>
            <div
                className={`modal fade  unqlifiedmodal-pop ${props.show }`}
                tabIndex={-1}
                role="dialog"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.95)"}}
            >
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content bor-b5">
                        <div className="modal-header noborder" />
                        <div className="modal-body">
                            <p className="unqualify-modal-p">
                          {  ModalQuestion[0][props.content]}
                            </p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={()=>props.setShow("hide")}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default UnqualifiedModal;