import React, { useContext, useState } from "react";

import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import privacy from '../../../../assets/img/privacy.png';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  disabled,
  getValues,
  slideChange,
  previousSlide,
  setEmailCheck,
  setPhoneCheck,
  checkValidation
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const validateSlide4 = async (e) => {
    e.preventDefault();
    const values = getValues();
    var emailVal = await trigger("txtEmail");
    var phoneVal;
    if (emailVal) {
      phoneVal = await trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }


    if (emailVal && phoneVal) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        slideChange();
      }
    }
  }
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    const email = values['txtEmail'];
    if (phone.length >= 11) {
      
      if (phone != "") {
        const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
        setPhoneCheck(getPhoneValidation.data.status_code)
        if (getPhoneValidation.data.status_code === 0) {
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          return 0;
        } else if (getPhoneValidation.data.status_code === 2) {
          setError("txtPhone", {
            type: "manual",
            message: "Phone Number Already Exist",
          });
          return 0;
        } else if (getPhoneValidation.data.status_code === 3) {
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Phone Number",
          });
          return 0;
        }
        else {
          setPhoneCheck(1);
          return 1;
        }
      }
    } 
    else {
      setPhoneCheck(0);
  }
  };
  const checkPhoneValid = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    if (phone.length >= 11) {
          phoneValidCheck();
    } else {
        setPhoneCheck(0);
    }
}
  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    const phone = values['txtPhone'];
    if (phone !== "" && email !== "") {
      document.getElementById("question_7").classList.add('anim_ylw');
    } else {
      document.getElementById("question_7").classList.remove('anim_ylw');
    }
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid)
      setEmailCheck(getEmailValidation.data.status_code);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };

  return (

    <div id="slide-7" className={`slidetop_adjust ${className}`}>
      {/* <ProgressBar value="95" />
      <h4 className="text-center">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </h4> */}

      <div className="input-section">
        <div className="input-group">
          <Email
            name="txtEmail"
            className="form-control mb-3"
            id="txtEmail"
            placeholder="Email Address"
            onBlur={emailValidCheck}
            validation={validation({
              required: "Please Enter Valid Email Address",
            })}

          ></Email>
          <div className="input-group-append">
        <span className="input-group-text privacyico">
                  <img src={privacy} alt="" />
               </span>  
               </div>
        </div>
        {validationMsg.txtEmail && (
          <span
            className="error_msg "
            id="email_err"
          >
            {validationMsg.txtEmail.message}
          </span>
        )}
      </div>
      <div className="input-group">
        <Telephone
          name="txtPhone"
          id="txtPhone"
          className="form-control nme_inpu mb-3"
          placeholder="Phone Number"
          onKeyUp={checkPhoneValid}
          onBlur={phoneValidCheck}
          onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
          maxlength="11"
          validation={validation({
            pattern: {
              value: /^[0-9]*$/i,
              message: "Please Enter Valid Phone Number",
            },
            required: "Please Enter Phone Number",
          })}
        ></Telephone>
        <div className="input-group-append">
        <span className="input-group-text privacyico">
          <img src={privacy} alt="" />
          
        </span>
        </div>
        {validationMsg.txtPhone && (
          <span
            className="error_msg "
            id="phone_err"
          >
            {validationMsg.txtPhone && "Please Enter Valid Phone Number"}
          </span>
        )}
      </div>
      <div className="col-lg-12 col-12">
        <InputButton
          name="question_7"
          className="btn btn-next mb-3"
          id="question_7"
          value="Continue >>"
          btnType="button"
          style={{ float: "none" }}
          disabled={disabled}
          onClick={checkValidation}
          // onClick={(e)=>props.slideChange(e,"question_7")}
        />
      </div>
      <p className="text-center">
        <span
          className="btn back01"
          name="back7n"
          id="back7n"
          children="<< Back"
          onClick={previousSlide}
        ></span></p>
    </div>
  );
};
export default ContactDetails;
