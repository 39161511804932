import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";

const QuestionCheckboxSlide = (props) => {
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [showError, setShowError] = useState("d-none");
  const question_id = props.questionnaire.questionId;
  const questionName = 'next' + question_id;

  const checkboxQuestion = props.questionnaire.answers.map((element, index) => {
    const name = `question_${question_id}_${element.value}`
    return (
      <div className="option_bank clearfix px-3" key={index}>
        <input
          type="checkbox"
          id={`question_${question_id}_${element.value}`}
          name={`question_${question_id}_${element.value}`}
          value={element.value}
          onClick={(e) => {
            insertValues(e);
          }}
          ref= {props.validation}
        />
        <label htmlFor={`question_${question_id}_${element.value}`} className="label_class">
          {element.label}
        </label>
      </div>
    );
  });
  const insertValues = (e) => {
    setShowError("d-none");
    var values = e.target.value;
    if (checkboxValues.includes(values)) {
      setCheckboxValues(checkboxValues.filter((item) => item !== values));
    } else {
      setCheckboxValues([...checkboxValues, values]);
    }
  };
  const pageValidate = (e) => {
    checkboxValues.length != 0
      ? props.slideChange(e, questionName)
      : setShowError("d-block");
  };

  return (
    <>
      <div
        className={`col-12 d ${props.showQuestionSlide}`}
      >
        <div className="form-group text-center">
          <h4>
            {props.questionnaire.question}
          </h4>
          <em className="">(Tick at least one box)</em>
          <br />
          <br />
          {checkboxQuestion}
          <div className="d-flex justify-content-center">
            <span
              className={`error_msg error_msg_custom errormsg2  ${showError}`}
              id="email_err"
            >
              Please select an option
            </span>
          </div>
        </div>
        <div className="col-lg-12 px-3">
          <Button
            type="button"
            className="btn btn-next mb-2 next4 btn_continue1"
            data-question_id={question_id}
            value={question_id}
            name={`next${question_id}`}
            onClick={(e) => {
              pageValidate(e);
            }}
            // question_id={question_id}
            buttonText={["Continue >>", <i className="bi bi-arrow-right" />]}
          />
          {/* Next
            <i className="bi bi-arrow-right"></i>
          </Button> */}
          {props.backBtn &&
            <Button
              type="button"
              className="btn back01"
              id={`back${question_id}`}
              name={`back${question_id}`}
              onClick={props.previousSlide}
              buttonText="<< Back "
            />}
        </div>
        <p className="text-center px-4">
          <i className="fa-solid fa-lock mx-2" />
          We will handle your data in accordance with our
          <a className="text-underline" data-toggle="modal" data-target="#Privacy" onClick={() => props.setInfoModalType("privacyPolicy")}> Privacy Policy</a>
        </p>
      </div>
    </>
  );
};

export default QuestionCheckboxSlide;
