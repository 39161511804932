import React from 'react'
import arrow_bt from '../../../../assets/img/AD_HDR_V2/arrow_bt.png'

const ProgressBar = ({ progress }) => {
  return (
    <>
      <div className="col-12 top_minus">
        <div className="progress progrss" data-percentage={progress}>
          <span className="progress-left">
            <span className="progress-bar" />
          </span>
          <span className="progress-right">
            <span className="progress-bar" id="progressBar" />
          </span>
          <div className="progress-value">
            <div id="progressBar_content">
              <a href="#slide1" className='text-decoration-none'>
                {progress !== 'start' ? (
                  <>
                    <span className="perce">{progress}%</span>
                    <br />
                    <span>completed</span>
                  </>
                ) : (
                  <>
                    <span className="perce">START</span>
                    <br />
                    <span>
                      <img src={arrow_bt} alt="" />
                    </span>
                  </>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgressBar