import React from 'react'
import * as InfoModalConstants from '../../../../Constants/InfoModalConstants';

const InfoModal = ({ infoModalType, setInfoModalType }) => {
  // // // // set this on the main page // // // //
  // const [infoModalType, setInfoModalType] = useState("");

  const selectedPolicy = {
    title: InfoModalConstants[infoModalType]?.title || "",
    body: InfoModalConstants[infoModalType]?.body || "",
  };

  const { title, body } = selectedPolicy;

  return (
    <>
      <div
        className={`modal fade ${infoModalType === '' ? 'hide' : 'show'}`}
        id="Privacy"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="privacyModalLabel"
        aria-modal="true"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="privacyModalLabel">
                {title}
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInfoModalType("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {body}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setInfoModalType("")}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoModal