import React, { useContext, useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import TextField from "../../../UI/TextField";
import privacy from '../../../../assets/img/AD_HDR_V2/privacy.png'
import Button from "../../../UI/Button";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Telephone from "../../../UI/Telephone";
import Email from "../../../UI/Email";
import GTMDataLayer from "../GTMDataLayer";
const PersonalDetails = (props) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  // 
  const [emailCheck, setEmailCheck] = useState();
  const [phoneCheck, setPhoneCheck] = useState();

  const question_id = props.questionId;

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;

    var txtFNameResult = await props.trigger("txtFName");;
    var txtLNameResult;
    var emailVal;
    var phoneVal;
    var termsVal;

    if (txtFNameResult) {
      txtLNameResult = await props.trigger("txtLName");
    }
    else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      emailVal = await props.trigger("txtEmail");
    }
    else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (emailVal && emailCheck == 1) {
      phoneVal = await props.trigger("txtPhone");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (!phoneVal || phoneCheck == 0) {
      document.getElementById("txtPhone").focus();
      props.setError("txtPhone", {
        type: "manual",
        message: "Invalid Phone",
      });
      return false;
    }
    // if (phoneVal) {
    //   termsVal = await props.trigger("agree_to_terms_of_business");
    //   if (!termsVal) {
    //     document.getElementById("agree_to_terms_of_business").focus();
    //   }
    // }
    // else {
    //   document.getElementById("txtPhone").focus();
    //   return false;
    // }


    if (
      !txtFNameResult ||
      !txtLNameResult ||
      !emailVal ||
      !phoneVal
      // || !termsVal
    ) {
      errorFlag = 1;
    }
    // 

    if (errorFlag === 0) {
      GTMDataLayer({
        question: "Contact details",
        answer: "",
      });

      props.formSubmit();
    } else {
      return false;
    }
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = (e.target.name == 'txtFName') ? 'First Name' : 'Last Name';
      props.setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };


  const checkPhoneValid = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone.length >= 11) {
      phoneValidCheck();
    } else {
      setPhoneCheck(0);
    }
  }
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    const email = values['txtEmail'];
    // if (phone !== "" && email !== "") {
    //   document.getElementById("question_7").classList.add('anim_ylw');
    // }
    if (phone.length >= 11) {
      if (phone !== "") {
        const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
        setPhoneCheck(getPhoneValidation.data.status_code)
        if (getPhoneValidation.data.status_code === 0) {
          props.setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          return 0;
        } else if (getPhoneValidation.data.status_code === 2) {
          props.setError("txtPhone", {
            type: "manual",
            message: "Phone Number Already Exist",
          });
          return 0;
        } else if (getPhoneValidation.data.status_code === 3) {
          props.setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Phone Number",
          });
          return 0;
        }
        else {
          setPhoneCheck(1);
          return 1;
        }
      }
    } else {
      setPhoneCheck(0);
    }
  };

  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    const phone = values['txtPhone'];
    // if (phone !== "" && email !== "") {
    //   document.getElementById("question_" + question_id).classList.add('anim_ylw');
    // } else {
    //   document.getElementById("question_" + question_id).classList.remove('anim_ylw');
    // }
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid)
      setEmailCheck(getEmailValidation.data.status_code);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };
  return (
    <>
      <div id={'slide' + question_id} className={`col-12 ${props.showQuestionSlide}`}>
        <div className="form-group px-3">
          <h4>
            What is the best way to contact you with an update about whether
            you've won compensation?
          </h4>
          <div className="input-group my-2">
            <TextField
              type="text"
              className="form-control "
              id="txtFName"
              name="txtFName"
              placeholder="Enter First Name"
              onBlur={namevalidate}
              validation={props.validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
            />
            {props.validationMsg.txtFName && (
              <span
                className="error_msg"
              >
                {props.validationMsg.txtFName.message}
              </span>
            )}
          </div>
          <div className="input-group my-2">
            <TextField
              type="text"
              className="form-control"
              id="txtLName"
              name="txtLName"
              placeholder="Enter Last Name"
              onBlur={namevalidate}
              validation={props.validation({
                required: "Please Enter Last Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid Last Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
            />
            {props.validationMsg.txtLName && (
              <span
                className="error_msg"
              >
                {props.validationMsg.txtLName.message}
              </span>
            )}
          </div>
          <div className="input-group my-2">
            <Email
              name="txtEmail"
              className="form-control"
              id="txtEmail"
              placeholder="Enter Email Address"
              onBlur={emailValidCheck}
              validation={props.validation({
                required: "Please Enter Valid Email Address",
              })}

            />
            <div className="input-group-append">
              <span className="input-group-text privacy-pic">
                <img src={privacy} alt="" />
              </span>
            </div>
            {props.validationMsg.txtEmail && (
              <span
                className="error_msg "
                id="email_err"
              >
                {props.validationMsg.txtEmail.message}
              </span>
            )}
          </div>
          <div className="input-group mb-4">
            <Telephone
              name="txtPhone"
              id="txtPhone"
              className="form-control"
              placeholder="Enter Mobile Number"
              onKeyUp={checkPhoneValid}
              onBlur={phoneValidCheck}
              onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
              maxlength="11"
              validation={props.validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Phone Number",
              })}
            />
            <div className="input-group-append">
              <span className="input-group-text privacy-pic">
                <img src={privacy} alt="" />
              </span>
            </div>
            {props.validationMsg.txtPhone && (
              <span
                className="error_msg "
                id="phone_err"
              >
                {props.validationMsg.txtPhone && "Please Enter Valid Phone Number"}
              </span>
            )}
          </div>
          <p className="text-center">
            Your mobile phone number is recommended to finalise details with
            us, and to receive automated SMS updates.
          </p>
          {/* <div className="text-center mb-3">
            <TextField
              type="checkbox"
              id="agree_to_terms_of_business"
              name="agree_to_terms_of_business"
              onChange={() => props.clearErrors("agree_to_terms_of_business")}
              validation={props.validation({
                required: "Agree to the Terms of Business to proceed",
              })}
            />
            <label className="ms-2" htmlFor="agree_to_terms_of_business">
              Click to agree to
              <a className="text-underline" data-toggle="modal" data-target="#terms_modal" onClick={() => props.setInfoModalType("termsPolicy")}> Terms of Business</a>
            </label>
            {props.validationMsg.agree_to_terms_of_business && (
              <span
                className="error_msg "
                id="email_err"
              >
                {props.validationMsg.agree_to_terms_of_business.message}
              </span>
            )}
          </div> */}
        </div>
        <div className="col-lg-12 px-3">
          <input
            type="button"
            className="btn btn-next next7"
            id={`question_ + ${question_id}`}
            defaultValue="Continue >>"
            onClick={basicDetailsValidation}
          />
          <input
            type="button"
            className="btn back01"
            id={`back${question_id}`}
            name={`back${question_id}`}
            defaultValue="<< Back"
            onClick={props.previousSlide}
          />
        </div>
        <p className="text-center px-4">
          <i className="fa-solid fa-lock mx-2" /> We will handle your data
          in accordance with our
          <a className="text-underline" data-toggle="modal" data-target="#Privacy" onClick={() => props.setInfoModalType("privacyPolicy")}> Privacy Policy</a>
        </p>
      </div>
    </>
  );
};
export default PersonalDetails;
