import React from 'react'

const LandingSlide = (props) => {
  return (
    <>
      <div id="slide_landing" className={`col-12 ${props.showQuestionSlide}`}>
        <div className="form-group px-4 py-3">
          <h4>Tap below to get started</h4>
          <label
            className="option_but label_class anim_ylw"
            htmlFor="landing_slide"
          >
            <span> CHECK NOW </span>
            <input
              tabIndex={1}
              type="radio"
              className="radio-button next1 "
              id="landing_slide"
              onClick={(e) => { props.slideChange(e, "landing_slide") }}
              name="landing_slide"
            />
          </label>
          <p className="text-center">
            <i className="fa-solid fa-clock-rotate-left mx-2" /> It only
            takes a minute
          </p>
        </div>
      </div>
    </>
  )
}

export default LandingSlide