import React, { useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from '../../../Utility/FbViewPixel';
import bigstock3 from "../../../assets/img/AD_HDR_V1/bigstock3.jpg";
import loaderreview2 from "../../../assets/img/AD_HDR_V1/loader-review2.gif";
import Header from "../../Includes/Layouts/AD_HDR_V1/Header";
import Footer from "../../Includes/Layouts/AD_HDR_V2/Footer";
import FormAD_HDR_V1 from "../../Forms/FormAD_HDR_V1";
import LoadingModal from '../../Includes/Layouts/AD_HDR_V2/LoadingModal'
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
const AD_HDR_V1 = () => {
    const [showLoadingModal, setLoadingModal] = useState(false);
    const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('AD_HDR_V1', 'AD_HDR_V1');
    console.log("i am here" + DynamicRouteNextPage);
    return (
        <>
            <AdtopiaLoadLP pageType="LP" splitName="AD_HDR_V1" />
            <FbViewPixel />
            <div className="housing-disrepair" id="housing-disrepair">
                {/* ____________________HEADER -  SECTION____________________ */}
                <Header />
                {/* ____________________form  part - FORM SECTION____________________ */}
                <FormAD_HDR_V1 splitName={DynamicRouteSplitName} nextPage={DynamicRouteNextPage} setLoadingModal={setLoadingModal} />
                {/* ____________________content_2  - SECTION____________________ */}
                <section className="content_2 ">
                    <div className="container">
                        <div className="row col-reverse">
                            <div className=" col-md-6 col-12 px-2">
                                <h3>Am I eligible to claim?</h3>
                                <p>
                                    {" "}
                                    Eligibility to make a housing disrepair claim depends on your
                                    individual situation. We have set criteria you need to be eligible
                                    for a claim. These can include:
                                </p>
                                <ul>
                                    <li> Whether you live in a house or a flat</li>
                                    <li>
                                        {" "}
                                        Whether you rent your home through social housing or privately
                                    </li>
                                    <li> The seriousness of the disrepair</li>
                                    <li>
                                        {" "}
                                        The impact of the disrepair on your home and the daily lives of
                                        those living there
                                    </li>
                                    <li>
                                        {" "}
                                        The length of time the property has been in a state of disrepair
                                    </li>
                                    <li>
                                        {" "}
                                        How many times you have reported the repairs needed to your
                                        housing association or council landlord{" "}
                                    </li>
                                    <li>
                                        {" "}
                                        The steps your landlord has or has not taken to repair your
                                        property
                                    </li>
                                </ul>
                                <p className="p_15">
                                    Understanding what housing disrepair is and if you’re eligible to
                                    claim can be tricky. So, we created this quick and easy guide to
                                    help explain it.
                                </p>
                                <div className="btn_div">
                                    <a className="btn_1" href="#form-part">
                                        {" "}
                                        CHECK NOW{" "}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 px-2 col-12 d-flex justify-content-center align-items-center ">
                                <img className="img-fluid" src={bigstock3} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* ____________________Footer  - SECTION____________________ */}
                <Footer />
                {/* ____________________unqlifiedmodal-pop - MODAL____________________ */}
                <div
                    className="modal fade  unqlifiedmodal-pop"
                    tabIndex={-1}
                    role="dialog"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        role="document"
                    >
                        <div className="modal-content bor-b5">
                            <div className="modal-header noborder" />
                            <div className="modal-body">
                                <p className="unqualify-modal-p">
                                    Sorry, if you have not complained to your landlord about these
                                    issues then we will not be able to assist you.
                                </p>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ____________________unqlifiedmodal-pop2 - MODAL____________________ */}
                <div
                    className="modal fade "
                    id="unqlifiedmodal-pop2"
                    tabIndex={-1}
                    role="dialog"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        role="document"
                    >
                        <div className="modal-content bor-b5">
                            <div className="modal-header noborder" />
                            <div className="modal-body">
                                <p className="unqualify-modal-p">
                                    Sorry, if your landlord sufficiently addressed these issues within 3
                                    months of complaint then we will not be able to assist you.
                                </p>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ____________________unqlifiedmodal-pop3 - MODAL____________________ */}
                <div
                    className="modal fade  unqlifiedmodal-pop3"
                    tabIndex={-1}
                    role="dialog"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        role="document"
                    >
                        <div className="modal-content bor-b5">
                            <div className="modal-header noborder" />
                            <div className="modal-body">
                                <p className="unqualify-modal-p">
                                    Sorry, if you owe more than £1,500 then we will not be able to
                                    assist you.
                                </p>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ____________________unqlifiedmodal-pop4 - MODAL____________________ */}
                <div
                    className="modal fade "
                    id="unqlifiedmodal-pop4"
                    tabIndex={-1}
                    role="dialog"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        role="document"
                    >
                        <div className="modal-content bor-b5">
                            <div className="modal-header noborder" />
                            <div className="modal-body">
                                <p className="unqualify-modal-p">
                                    Sorry, if you do not reside in England or Wales, we will not be able
                                    to assist you.
                                </p>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ____________________id=analyzemodal- MODAL____________________ */}
                <LoadingModal
                    showLoadingModal={showLoadingModal}
                    setLoadingModal={setLoadingModal}
                />
                {/* <div
                    className="modal modal_1 fade "
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                    id="analyzemodal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div id="slide6k" className="col-12  text-center">
                                    <img src={loaderreview2} alt="" />
                                    <h3 id="h3_first">Loading your responses</h3>
                                    <div id="h3_second" style={{ display: "none" }}>
                                        <h3> Great News! </h3>
                                        <h4>
                                            We have all of the information we need to work on getting you
                                            compensated!
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* ____________________id=interactive_img- MODAL____________________ */}
                <div
                    className="modal fade"
                    tabIndex={-1}
                    role="dialog"
                    id="interactive_img"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                >
                    <div
                        className="modal-dialog  modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div
                            className="modal-content"
                            style={{ border: "0px !important", background: "none" }}
                        >
                            <div className="modal-body text-center" style={{ padding: 0 }}>
                                <div className="disrepair_canvas d-none d-md-block">
                                    <div className="col-12">
                                        <div
                                            className="pointer_div pointer1"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=" Faulty heating system - Tenants are entitled to a functioning heating system"
                                        ></div>
                                        <div
                                            className="pointer_div pointer2"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=" Vegetation growth & build-up on exterior of building - It's the landlord's responsibility to deal with this"
                                        ></div>
                                        <div
                                            className="pointer_div pointer3"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=" Leaking roof resulting in damp problems - Under law, your landlord is responsible for roof repairs"
                                        ></div>
                                        <div
                                            className="pointer_div pointer4"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Damp & mould, caused by faulty guttering - This is your landlord's responsibility to fix"
                                        ></div>
                                        <div
                                            className="pointer_div pointer5"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="External drainage problems - Your landlord should fix any broken or faulty guttering, downpipes or drains"
                                        ></div>
                                        <div
                                            className="pointer_div pointer6"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Penetrating damp due to rotten window frames - Your landlord is responsible for fixing"
                                        ></div>
                                        <div
                                            className="pointer_div pointer7"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Plumbing problems - Leaks or damage caused by plumbing disrepair is your landlord's responsibility to fix"
                                        ></div>
                                        <div
                                            className="pointer_div pointer8"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Damaged and worn brickwork - Repointing when necessary is your landlord's responsibility"
                                        ></div>
                                        <div
                                            className="pointer_div pointer9"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Unsafe stairs - If your stairs are not safe due to missing handrails/banisters, broken tread or loose flooring that is causing a trip hazard - your landlord should ensure repairs are done"
                                        ></div>
                                        <div
                                            className="pointer_div pointer10"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Rising damp - Can cause issues with plaster, skirting boards and flooring. It's your landlord's responsibility to fix the problem"
                                        ></div>
                                        <div
                                            className="pointer_div pointer11"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Unsafe flooring - Any faulty flooring that could cause trips or falls should be fixed by your landlord"
                                        ></div>
                                        <div
                                            className="pointer_div pointer12"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Vermin (rats, mice, cockroaches) - if pests were there when you moved in, or if they enter the property because of other disrepair, your landlord should take responsibility"
                                        ></div>
                                    </div>
                                </div>
                                {/* mobile */}
                                <div className="disrepair_canvas_mob  d-block d-md-none">
                                    <div className="logo_pointer"></div>
                                    <div className="col-12">
                                        <div
                                            className="pointer_div pointer1"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=" Faulty heating system - Tenants are entitled to a functioning heating system"
                                        ></div>
                                        <div
                                            className="pointer_div pointer2"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=" Vegetation growth & build-up on exterior of building - It's the landlord's responsibility to deal with this"
                                        ></div>
                                        <div
                                            className="pointer_div pointer3"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=" Leaking roof resulting in damp problems - Under law, your landlord is responsible for roof repairs"
                                        ></div>
                                        <div
                                            className="pointer_div pointer4"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Damp & mould, caused by faulty guttering - This is your landlord's responsibility to fix"
                                        ></div>
                                        <div
                                            className="pointer_div pointer5"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="External drainage problems - Your landlord should fix any broken or faulty guttering, downpipes or drains"
                                        ></div>
                                        <div
                                            className="pointer_div pointer6"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Penetrating damp due to rotten window frames - Your landlord is responsible for fixing"
                                        ></div>
                                        <div
                                            className="pointer_div pointer7"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Plumbing problems - Leaks or damage caused by plumbing disrepair is your landlord's responsibility to fix"
                                        ></div>
                                        <div
                                            className="pointer_div pointer8"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Damaged and worn brickwork - Repointing when necessary is your landlord's responsibility"
                                        ></div>
                                        <div
                                            className="pointer_div pointer9"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Unsafe stairs - If your stairs are not safe due to missing handrails/banisters, broken tread or loose flooring that is causing a trip hazard - your landlord should ensure repairs are done"
                                        ></div>
                                        <div
                                            className="pointer_div pointer10"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Rising damp - Can cause issues with plaster, skirting boards and flooring. It's your landlord's responsibility to fix the problem"
                                        ></div>
                                        <div
                                            className="pointer_div pointer11"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Unsafe flooring - Any faulty flooring that could cause trips or falls should be fixed by your landlord"
                                        ></div>
                                        <div
                                            className="pointer_div pointer12"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Vermin (rats, mice, cockroaches) - if pests were there when you moved in, or if they enter the property because of other disrepair, your landlord should take responsibility"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ____________________id=Privacy- MODAL____________________ */}
                <div
                    className="modal fade"
                    id="Privacy"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="privacyModalLabel"
                    aria-modal="true"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="privacyModalLabel">
                                    Privacy Policy
                                </h3>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {" "}
                                    <span aria-hidden="true">×</span>{" "}
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet, consectetur </li>
                                    <li>Lorem ipsum dolor sit amet, consectetur </li>
                                    <li>Lorem ipsum dolor sit amet, consectetur </li>
                                    <li>Lorem ipsum dolor sit amet, consectetur </li>
                                </ul>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam. .
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.{" "}
                                </p>
                                <h4>Lorem ipsum dolor sit amet, consectetur </h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec
                                    dapibus eros. Vivamus vulputate ligula vestibulum, lobortis orci
                                    pretium, tincidunt diam.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );

};
export default AD_HDR_V1;