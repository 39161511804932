import React, { useState } from "react";
import ProgressBar from "../Split_1/ProgressBar";
const QuestionCheckBox = (props) => {
    const[textbox,setTextbox] = useState("hide")
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [showError, setShowError] = useState("d-none");
    const subText = props.questionnaire.questionSubText;
    const question_id = props.questionnaire.questionId;
    const radioName = 'question_' + question_id;
    const length = props.questionnaire.answers.length;
    const answerBlock = props.questionnaire.answers.map((item, index) => {
        return (

            <div className="option_bank clearfix px-3" id="">
                <input
                    type="checkbox"
                    value={item.value}
                    className="bank-name"
                    id={`question_${question_id}_${item.value}`}
                    name={`question_${question_id}_${item.value}`}
                    onClick={(e) => {
                        insertValues(e);
                    }}
                    ref={props.validation}
                />
                <label for={`question_${question_id}_${item.value}`}>
                    <span>{item.label}</span> </label>
            </div>

        )
    });
    const insertValues = (e) => {

        (e.target.value == 15) ? setTextbox("show") : setTextbox("hide");
           

        console.log(e.target.value);
        setShowError("d-none");
        var values = e.target.value;
        if (checkboxValues.includes(values)) {
            setCheckboxValues(checkboxValues.filter((item) => item !== values));
        } else {
            setCheckboxValues([...checkboxValues, values]);
        }
    };
    const pageValidate = (e) => {
        checkboxValues.length != 0
            ? props.slideChange(e, radioName)
            : setShowError("d-block");
    };

    return (
        <>
        <div id="slide3n" className={`col-12 top_minus ${props.showQuestionSlide}`}>
            <ProgressBar value={props.value}/>
            <div className={`form-group text-center`}>
                <h4>
                    {props.questionnaire.question}
                </h4>
                <em className="">Please select all that apply. Then click the "Continue &gt;&gt;" button.</em>
                <br /><br />

                {answerBlock}
                </div>
                <div
                    id="otherdata"
                    className={`form-group col-12 p-0 other-data text-center ${textbox}`}
                    
                >
                    {/* <input
                        name="txtOther"
                        placeholder="Enter Other Reasons"
                        className="form-control anim_ylw"
                        defaultValue=""
                        ref={props.validation}
                    /> */}
                    <div
                        className="form-group col-12 p-0 text-center"
                        style={{ display: "none" }}
                    >
                        <span className="error_msg">Please Enter</span>
                    </div>
                    <a data-toggle="modal" data-target="#interactive_img">
                        {/* <i>View Guide</i> */}
                    </a>
                    <div className="error_msg " />
                </div>

                <div className="d-flex justify-content-center">
                    <span
                        className={`error_msg error_msg_custom errormsg2  ${showError}`}
                        id="email_err"
                    >
                        Please select an option
                    </span>
                </div>
                <div className="col-lg-12 px-3">
                    <input type="button" className="btn btn-next next03n btn_continue1" id={`question_${question_id}`} value="Continue >>" onClick={(e) => { pageValidate(e) }} />
                    {question_id != '1' &&
                        <input
                            type="button"
                            name={`back${question_id}`}
                            className="btn back01"
                            id={`back${question_id}`}
                            defaultValue="<< Back"
                            onClick={props.previousSlide}
                        />}
                </div>
            </div>
        </>
    );
};

export default QuestionCheckBox;
