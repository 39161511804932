import React, { useState } from "react";
import UnqualifiedModal from "../AD_HDR_V1/UnqualifiedModal";
import ProgressBar from "../Split_1/ProgressBar";
const QuestionRadioSlide = (props) => {
    const subText = props.questionnaire.questionSubText;
    const question_id = props.questionnaire.questionId;
    const radioName = 'question_' + question_id;
    const length = props.questionnaire.answers.length;
    const [click, setClick] = useState();
    const [show, setShow] = useState();
    const handleSubmit = (e) => {
        if(e.target.value != ''){
        setClick(e.target.value);
        }
        // if(e.target.name == "question_8"){
        //     document.getElementById("quest_8_err").classList.remove('show');
        //     document.getElementById("quest_8_err").classList.add('hide');
        // }
        if ((e.target.value === "2") || (e.target.value === "5") || (e.target.value === "7") || (e.target.value === "17") || (e.target.value === "19") || (e.target.value === "22") || (e.target.value === "25") || (e.target.value === "31") || (e.target.value === "26")) setShow("show")
        if ((e.target.value !== "2") && (e.target.value !== "5") && (e.target.value !== "7") && (e.target.value !== "17") && (e.target.value !== "19") && (e.target.value !== "22") && (e.target.value !== "25") && (e.target.value !== "31") && (e.target.value !== "26") ) {
            if (props.showLoadingModalForNextPage) {
                props.setLoadingModal("show");
                setTimeout(() => {
                  props.slideChange(e, radioName);
                }, 3000);
              } else {
                props.slideChange(e, radioName);
              }
        }
    }
    const answerBlock = props.questionnaire.answers.map((item, index) => {
        return (
            <>

                <div className="option_bank clearfix px-3" >

                    <input
                        type="radio"
                        value={item.value}
                        className="bank-name"
                        id={'question_' + question_id + '_' + item.value}
                        name={'question_' + question_id}
                        onClick={(e) => { handleSubmit(e) }}
                        ref= {props.validation}
                    />
                     
                        <label className={`option_but next0${question_id}n ${(item.value==25)  ? 'no-radio-active-css' : ''}`} id={`next0${question_id}n`}  for={'question_' + question_id + '_' + item.value} key={index}> <span>{item.label}</span></label>

                </div>


            </>

        )
    })

    return (
        <>
            {((click === "2") || (click === "5") || (click === "7") || (click === "17") || (click === "19") || (click === "22") || (click === "25")  || (click === "31") || (click === "26")) && <UnqualifiedModal
                show={show}
                setShow={setShow}
                content={click}
            />}
            <div id="slide1" className={`col-12 top_minus ${props.showQuestionSlide}`}>
                <ProgressBar value={props.value} />
                <div className={`form-group px-3 py-3`}>
                    <h4 className="pad_only">
                        {props.questionnaire.question}
                    </h4>
                    {answerBlock}

                </div>
                <div className="col-lg-12 p-0">
                    {/* {question_id == '8' &&
                    <div>
                        <span
                            className="error_msg error_msg_custom errormsg2 text-center hide"
                            id="quest_8_err"
                        >
                            Please select an option
                        </span>
                        <input type="button" data-toggle="modal"
                            data-target="#analyzemodal" name={'question_' + question_id} class="btn btn-next next07n btn_continue1" id="" value="Continue >>" onClick={(e)=>props.modalShow()} />

                            
                        </div>
                            } */}
                    {question_id != '1' &&
                        <input
                            type="button"
                            name={`back${question_id}`}
                            className="btn back01"
                            id={`back${question_id}`}
                            defaultValue="<< Back"
                            onClick={props.previousSlide}
                        />}
                </div>
            </div>
        </>
    )
};

export default QuestionRadioSlide;
