import React, { useState } from 'react'
import InfoModal from './InfoModal'

const Footer = () => {
  const [infoModalType, setInfoModalType] = useState("");
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                Accell Digital Limited. Company Number: 13539012. VAT Number:
                389529826. Registered Address: Accell Digital, 7 Bell Yard, London
                WC2A 2JR. ICO Number: ZB239690.
              </p>
              <p>
                Accell Digital is Authorised and Regulated by The Financial Conduct
                Authority in respect of regulated claims management activities. Firm
                Reference Number FRN964366.
              </p>
              <p>
                <a className='text-underline' data-toggle="modal" data-target="#Privacy" onClick={() => setInfoModalType("privacyPolicy")}>
                  Privacy Policy
                </a>
                &nbsp; &nbsp; 
                <a className='text-underline' data-toggle="modal" data-target="#Privacy" onClick={() => setInfoModalType("cookiePolicy")}>
                  Cookie Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <InfoModal
        infoModalType={infoModalType}
        setInfoModalType={setInfoModalType}
      />
    </>
  )
}

export default Footer