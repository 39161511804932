import React, { useContext, useState, useEffect, useRef } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import RadioButtonQuest from "../UI/RadioButtonQuest";
import InputButton from "../UI/InputButton";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import Header from "../Includes/Layouts/AD_HDR_V1/Header";
import Footer from "../Includes/Layouts/AD_HDR_V2/Footer";
import bnr from "../../assets/img/AD_HDR_V1/bnr.jpg";
import bigstock2p from "../../assets/img/AD_HDR_V1/bigstock2p.jpg";
import bigstock3 from "../../assets/img/AD_HDR_V1/bigstock3.jpg";
import camera from "../../assets/img/AD_HDR_V1/camera.png";
import browse from "../../assets/img/AD_HDR_V1/browse.png";
import ProgressBar from "../Includes/Layouts/Split_1/ProgressBar";

const IdUpload = () => {
  const { isCheck } = CheckUUID();
  const history = useHistory();
  const isCheckResponse = isCheck();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { saveIdUpload, isLoading } = useIdUpload();
  const [selectedFile, setSelectedFile] = useState();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [base64Image, setBase64Image] = useState();
  const uploadRef = useRef();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','id-upload');
  const [file, setFile] = useState();
  const [preview, setPreview] = useState('hide');
  const [fname, setFname] = useState();

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem('formData'));
    const fname =  Capitalize(formdata.txtFName);
    setFname(fname);

    }, [fname]);
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const nextSlide = (e) => {
    uploadRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const userfile = {
    preview: undefined,
    title: undefined
  }
	const changeFilesHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		let imageFile = event.target.files[0];
    
		if (imageFile) {
			setIsFilePicked(true);
			let extension = event.target.files[0].type;
			let imgsize = event.target.files[0].size;
      
			let allowedExtensions = ['image/jpeg', 'image/png'];
			if (allowedExtensions.indexOf(extension) === -1) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'The uploaded ID type should be an image in jpeg,png or jpg' });
			} else if (imgsize > 1024 * 1024 * 20) {
				setIsInValidfFile(true);
				setErrorMessage({ txt: 'Please choose a file less than 20mb' });
			} else {
        setFile(URL.createObjectURL(event.target.files[0]));
        setPreview("show");
				setSelectedFileType(event.target.name);
				setIsInValidfFile(false);
			}
			userfile.preview = URL.createObjectURL(imageFile);
			userfile.title = imageFile.name;

     
			if (imageFile) {
				let reader = new FileReader();
				reader.onload = _handleReaderLoaded.bind(this);
				reader.readAsDataURL(imageFile);
			}
		} else {
			setIsInValidfFile(true);
			setIsFilePicked(false);
			userfile.preview = undefined;
			userfile.title = undefined;
		}
	};
	const _handleReaderLoaded = (readerEvt) => {
		let binaryString = readerEvt.target.result;
		setBase64Image({
			base64TextString: binaryString
		});
	};
  const handleChange = (e) => {
        
    }
  const closeModal = (e) =>{
    setPreview("hide");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let uploadData = '';
    if(isFilePicked){
      uploadData = {
        "imageFile": base64Image.base64TextString,
        "captureType": selectedFileType.substring(selectedFileType.indexOf("doc")),
        "documentType": selectedFileType.substr(0, selectedFileType.indexOf('_'))
      };
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        'user_docs_store'
      );
      history.push(
        "/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid  + "&splitName=" + DynamicRouteSplitName
      );
    }else{
      setIsInValidfFile(true);
      setErrorMessage({ txt: 'Please upload a file' });
    }
  }
  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
  const uuid = getUuid();
  return (
    <>
    <div className="housing-disrepair">
      {/* <GetVisitorsParams /> */}
      <Header />
  {/* ____________________form  part - FORM SECTION____________________ */}
  <section className="form-part">
    <div className="container nopad">
      <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
        <form autoComplete="off" >
          {/* ____________________slide1 -start____________________ */}
          <div id="slide1" className="col-12 top_minus ">
            {/* progress start */}
          <ProgressBar value="95"/>
            {/* progress end */}
            <div className="form-group">
              <h4>
                Thanks {fname}, now we need you to use your phone's camera to
                take as much photographic evidence as possible for our team to
                assess the severity of the issue(s).
              </h4>
              <p className="upload_note">
                {" "}
                (If you’re not home, you can SKIP for now and will send you a
                link to upload photos to.){" "}
              </p>
              <div className=" imageupload px-2">
                <label
                  type="button"
                  htmlFor="idTypeFileCamera"
                  className="upload"
                  data-toggle="modal"
                  data-target="#uploadimage"
                >
                  <img
                    className="ful-wid pr-2"
                    src={camera}
                    alt=""
                  />{" "}
                  Take Photo via Device{" "}
                  <input 
                  type="file"
                  id="idTypeFileCamera"
                  style={{display:"none"}}
                  accept="image/*"
                  capture="user"
                  value=""
                  onChange={changeFilesHandler}
                  />
                </label>
                <label
                  type="button"
                  htmlFor="idTypeFile"
                  className="upload radioaclick"
                  data-toggle="modal"
                  data-target="#uploadimage"
                >
                  <img
                    className="ful-wid pr-2"
                    src={browse}
                    alt=""SetModalClass2
                  />{" "}
                  Select File from Your Device{" "}
                  <input
                  style={{display:"none"}}
                  type="file"
                  accept="image/*"
                  id="idTypeFile"
                  value=""
                  onChange={changeFilesHandler}
                  />
                </label>
                {isInValidfFile &&
												<span
                        className="error_msg"
                        id="email_err"
                      >
                      {errorMessage.txt}</span>
											}
                {/* <label type="button" for="Skip" class="red_btn"> Skip </label> */}
                <input
                  type="button"
                  className="btn back01"
                  Value="Skip >>"
                  onClick={(e) => {       
                    e.preventDefault();    
                    history.push(
                      "/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid  + "&splitName=" + DynamicRouteSplitName
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {/* ____________________id=uploadimage- MODAL____________________ */}
          <div
            className={`modal fade ${preview} bg_blur`}
            id="uploadimage"
            tabIndex={-1}
            aria-labelledby="imageuploadModalLabel"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content content_center">
                <div className="modal-body">
                  <img className="img-fluid" src={file} alt="" />
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-success"
                  onClick={handleSubmit}>
                    Submit
                  </button>
                  <input className="d-none" type="file" id="upload_img1" />
                  <button
                    type="button"
                    id="upload_img1"
                    className="btn red_btn2"
                    onClick={closeModal}
                  >Retake
                </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  {/* ____________________content_1  - SECTION____________________ */}
  <section className="content_1">
    <div className="container">
      <div className="row">
        <div className=" col-md-6 col-12 d-flex justify-content-center align-items-center">
          <img className="" src={bigstock2p} alt="" />
        </div>
        <div className=" col-md-6 col-12 px-2">
          <h3 className=" text-center text-md-start"> What can I claim for?</h3>
          <p className="text-center text-md-start">
            {" "}
            You could be eligible to claim for housing disrepair if your home
            has any of the below issues:
          </p>
          <ul>
            <li> Rising damp, leading to mould </li>
            <li> Penetrating damp, leading to mould</li>
            <li> Non-functioning or unsafe heating system or boiler</li>
            <li> Unsafe flooring or staircases </li>
            <li> Rotten doors or window frames </li>
            <li> Leaking pipes or cracked sanitation equipment </li>
            <li> Faulty electrics </li>
            <li> Loose tiles or brickwork </li>
            <li> Vermin or insect infestation </li>
          </ul>
          <p>
            Is your property full of damp? Perhaps there’s unsafe flooring or
            faulty electrics. You could be eligible to make a claim to help get
            the repairs done and potentially get some compensation too. Find out
            how to start your housing disrepair claim.
          </p>
          <p>
            You may also make a compensation claim for personal injury or an
            equality act claim if the housing disrepair has caused you physical
            or mental health issues, discrimination or has directly put you or
            anyone in the household at risk of harm, including high and
            sustained levels of stress and suffering. Find out more about
            Equality Act claims.
          </p>
        </div>
      </div>
    </div>
  </section>
  {/* ____________________content_2  - SECTION____________________ */}
  <section className="content_2 ">
    <div className="container">
      <div className="row col-reverse">
        <div className=" col-md-6 col-12 px-2">
          <h3>Am I eligible to claim?</h3>
          <p>
            {" "}
            Eligibility to make a housing disrepair claim depends on your
            individual situation. We have set criteria you need to be eligible
            for a claim. These can include:
          </p>
          <ul className="ul_pad_1">
            <li> Whether you live in a house or a flat</li>
            <li>
              {" "}
              Whether you rent your home through social housing or privately
            </li>
            <li> The seriousness of the disrepair</li>
            <li>
              {" "}
              The impact of the disrepair on your home and the daily lives of
              those living there
            </li>
            <li>
              {" "}
              The length of time the property has been in a state of disrepair
            </li>
            <li>
              {" "}
              How many times you have reported the repairs needed to your
              housing association or council landlord{" "}
            </li>
            <li>
              {" "}
              The steps your landlord has or has not taken to repair your
              property
            </li>
          </ul>
          <p className="p_15">
            Understanding what housing disrepair is and if you’re eligible to
            claim can be tricky. So, we created this quick and easy guide to
            help explain it.
          </p>
          <div className="btn_div">
            <a className="btn_1" href="#housing-disrepair">
              {" "}
              CHECK NOW{" "}
            </a>
          </div>
        </div>
        <div className="col-md-6 col-12 d-flex justify-content-center align-items-center ">
          <img className="img-fluid" src={bigstock3} alt="" />
        </div>
      </div>
    </div>
  </section>
  <Footer/>
  </div>
</>

  );
};

export default IdUpload;
