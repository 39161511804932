import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const SimpleModal = ({ show, modalBody, handleClose }) => {
  return (
    <>
      <div
        className={`modal fade  unqlifiedmodal-pop ${show}`}
        tabIndex={-1}
        role="dialog"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content bor-b5">
            <div className="modal-header noborder" />
            <div className="modal-body">
              <p className="unqualify-modal-p">
                {modalBody}
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SimpleModal