import React from "react";
import SelectBox from "../../../../UI/SelectBox";
import AddressTextField from "../../../../UI/AddressTextField";
import ProgressBar from "../../Split_1/ProgressBar";
const PostCode_AD_HDR_V1 = (props)=>{
    return(
        <>
              <div
                                id="slide9n"
                                className={`col-12 top_minus ${props.showQuestionSlide}`}
                                style={{ display: "none" }}
                            >
                                <ProgressBar value={props.value}/>
                                    <h3>What is your current address? </h3>
                                    <div className="col-12 p-0">
                                        <div className="mb-3">
                                            <div className="input-group post">
                                                <AddressTextField
                                                    type="text"
                                                    className="form-control mob-wd"
                                                    name="address1"
                                                    id="poscod"
                                                    placeholder="Your Postcode"
                                                />
                                            </div>
                                            {/* *****error message span here***** */}
                                            <div className="error_msg" style={{ display: "none" }}>
                                                <span>Please Select Number of Months</span>
                                            </div>
                                            {/* ********************************* */}
                                        </div>
                                        <div className="mb-3" id="lookid" style={{ display: "block" }}>
                                            <button
                                                id="findading"
                                                className="btn btn-next anim_ylw"
                                                type="button"
                                            >
                                                Lookup Address
                                            </button>
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                    <div
                                        className="row"
                                        id="currentAddressCollapse"
                                        style={{ display: "none" }}
                                    >
                                        <div className="mb-3 col-lg-12 col-12">
                                            <select
                                                className="form-select form-control"
                                                name="address1"
                                                id="address1"
                                            >
                                                <option value="">Please Select Address</option>
                                                <option value="15929549_0S_F">
                                                    1 George Pateman Court Tenison Road Cambridge
                                                    Cambridgeshire
                                                </option>
                                                <option value="15929560_0S_F">
                                                    2 George Pateman Court Tenison Road Cambridge
                                                    Cambridgeshire
                                                </option>
                                                <option value="15929571_0S_F">
                                                    3 George Pateman Court Tenison Road Cambridge
                                                    Cambridgeshire
                                                </option>
                                                <option value="15929574_0S_F">
                                                    4 George Pateman Court Tenison Road Cambridge
                                                    Cambridgeshire
                                                </option>
                                                <option value="15929575_0S_F">
                                                    5 George Pateman Court Tenison Road Cambridge
                                                    Cambridgeshire
                                                </option>
                                                <option value="15929576_0S_F">
                                                    6 George Pateman Court Tenison Road Cambridge
                                                    Cambridgeshire
                                                </option>
                                            </select>
                                            {/* *****error message span here***** */}
                                            <div className="error_msg" style={{ display: "none" }}>
                                                <span>Please Select Number of Months</span>
                                            </div>
                                            {/* ********************************* */}
                                        </div>
                                        <div className="col-12">
                                            <input
                                                type="submit"
                                                className="btn btn-next "
                                                defaultValue="Submit"
                                            />
                                            <input
                                                type="button"
                                                className="btn back01"
                                                id="back9n"
                                                defaultValue="<< Back"
                                            />
                                        </div>
                                    </div>
                                    {/* next-button */}
                                </div>
            
        </>
    );
};
export default PostCode_AD_HDR_V1