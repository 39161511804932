import React, { lazy } from 'react';

const AD_HDR_V1 = lazy(() => import('./Components/Pages/Splits/AD_HDR_V1'));
const AD_HDR_V2 = lazy(() => import('./Components/Pages/Splits/AD_HDR_V2'));


const AppSplitList = (props) => {

    const SplitListMap = {
        'AD_HDR_V1': AD_HDR_V1,
        'AD_HDR_V2': AD_HDR_V2
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;