import React, { useEffect, useReducer, useState, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import QuestionRadioSlide from "../Includes/Layouts/AD_HDR_V2/QuestionRadioSlide";
import QuestionCheckboxSlide from "../Includes/Layouts/AD_HDR_V2/QuestionCheckboxSlide";
import LandingSlide from "../Includes/Layouts/AD_HDR_V2/LandingSlide";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import {
  questinnare1,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare8,
} from "../../Constants/Questions_AD_HDR_V2";
import ProgressBar from "../Includes/Layouts/AD_HDR_V2/ProgressBar";
import PersonalDetails from "../Includes/Layouts/AD_HDR_V2/PersonalDetails";
import GTMDataLayer from "../Includes/Layouts/GTMDataLayer";

const FormAD_HDR_V2 = (
  // { handleShowModal, setModalBody, setLoadingModal, setInfoModalType }
  props
) => {

  const [progress, setProgress] = useState("start");
  const initialState = {
    showLandingSlide: "d-block",
    showQuestionSlide1: "d-none",
    showQuestionSlide2: "d-none",
    showQuestionSlide3: "d-none",
    showQuestionSlide4: "d-none",
    showQuestionSlide5: "d-none",
    showQuestionSlide6: "d-none",
    showQuestionSlide7: "d-none",
    showQuestionSlide8: "d-none",
    showQuestionSlide9: "d-none",
  };
  const QuestionReducer = (state, action) => {
    //////////////////////////////////
    //  landing_slide  //  back2    //
    //  question_2     //  back11   //
    //  question_11    //  back4    //
    //  next4          //  back10   //
    //  question_10    //  back5    //
    //  question_5     //  back3    //
    //  question_3     //  back9    //
    //  question_9     //  back7    //
    //  question_7     //  backform //
    //////////////////////////////////

    // // // // progress bar percentage // // // //
    if (action.type === "nextQuestionSlide") {
      const slideToProgressMap = {
        landing_slide: "10",
        question_2: "20",
        question_11: "25",
        next4: "30",
        question_10: "40",
        question_5: "50",
        question_3: "60",
        question_9: "70",
        question_7: "80",
      };
      const slideName = action.payload.clickedSlide.slide;
      const progress = slideToProgressMap[slideName] || "";
      setProgress(progress);
    } else if (action.type === "backQuestionSlide") {
      const slideToProgressMap = {
        back2: "start",
        back11: "10",
        back4: "20",
        back10: "25",
        back5: "30",
        back3: "40",
        back9: "50",
        back7: "60",
        backform: "70",
      };
      const slideName = action.payload.prevSlide.slide;
      const progress = slideToProgressMap[slideName] || "";
      setProgress(progress);
    }
    // // // // // // // // // // // // // // // //

    switch (action.type) {
      case "nextQuestionSlide": {
        if (action.payload.clickedSlide.slide == "landing_slide") {
          return {
            ...state,
            showLandingSlide: "d-none",
            showQuestionSlide1: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_2") {
          return {
            ...state,
            showQuestionSlide1: "d-none",
            showQuestionSlide2: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_11") {
          return {
            ...state,
            showQuestionSlide2: "d-none",
            showQuestionSlide3: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "next4") {
          return {
            ...state,
            showQuestionSlide3: "d-none",
            showQuestionSlide4: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_10") {
          return {
            ...state,
            showQuestionSlide4: "d-none",
            showQuestionSlide5: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_5") {
          return {
            ...state,
            showQuestionSlide5: "d-none",
            showQuestionSlide6: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_3") {
          return {
            ...state,
            showQuestionSlide6: "d-none",
            showQuestionSlide7: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_9") {
          return {
            ...state,
            showQuestionSlide7: "d-none",
            showQuestionSlide8: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "question_7") {
          return {
            ...state,
            showQuestionSlide8: "d-none",
            showQuestionSlide9: "d-block",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "backQuestionSlide": {
        if (action.payload.prevSlide.slide == "back2") {
          return {
            ...state,
            showLandingSlide: "d-block",
            showQuestionSlide1: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back11") {
          return {
            ...state,
            showQuestionSlide1: "d-block",
            showQuestionSlide2: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back4") {
          return {
            ...state,
            showQuestionSlide2: "d-block",
            showQuestionSlide3: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back10") {
          return {
            ...state,
            showQuestionSlide3: "d-block",
            showQuestionSlide4: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back5") {
          return {
            ...state,
            showQuestionSlide4: "d-block",
            showQuestionSlide5: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back3") {
          return {
            ...state,
            showQuestionSlide5: "d-block",
            showQuestionSlide6: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back9") {
          return {
            ...state,
            showQuestionSlide6: "d-block",
            showQuestionSlide7: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back7") {
          return {
            ...state,
            showQuestionSlide7: "d-block",
            showQuestionSlide8: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "backform") {
          return {
            ...state,
            showQuestionSlide8: "d-block",
            showQuestionSlide9: "d-none",
          };
        } else {
          return {
            ...state,
          };
        }
      }
    }
  };
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState();
  const [stage, setStage] = useState(1)
  const [prevSlide, setPrevSlide] = useState();
  const splitForm = useRef(null);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const history = useHistory();
  const field = "pid";
  const scrollfrmv2div = useRef([]);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (prevSlide) {
      dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
    }
  }, [prevSlide]);

  const slideChange = (e, radioName) => {
    gtmDataLayer(radioName);

    window.scrollTo(0, 300);
    setStage(stage + 1);
    setClickedSlide({ slide: radioName });
  }
  const previousSlide = (e) => {
    var previous_slide = e.target.id;
    window.scrollTo(0, 300);
    setStage(stage - 1);
    setPrevSlide({ slide: previous_slide })
  }

  const gtmDataLayer = (current_question, element = null) => {
    let question = "";
    let answer = "";
    switch (current_question) {
      case "landing_slide":
        question = "Check now";
        break;
      case "question_2":
        question = "Rent From";
        break;
      case "question_11":
        question = "Repairs to complete";
        break;
      case "next4":
        question = "Problems of property";
        break;
      case "question_10":
        question = "proceed with solicitor";
        break;
      case "question_5":
        question = "Complained to landlord";
        break;
      case "question_3":
        question = "Still living in property";
        break;
      case "question_9":
        question = "Claimed Previosly";
        break;
      case "question_7":
        question = "behind rent";
        break;

    }

    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  // validation
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const formSubmit = async () => {
    // const form = splitForm.current;
    const values = getValues();
    const formData = values;
    console.log(formData);
    // if (formData.address1 !== "") {
    //   const txtUdprn = form["txtUdprn"].value;
    //   const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
    //   const txtPz_mailsort = form["txtPz_mailsort"].value;
    //   const txtStreet = form["txtStreet"].value;
    //   formData.txtUdprn = txtUdprn;
    //   formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
    //   formData.txtPz_mailsort = txtPz_mailsort;
    //   formData.txtStreet = txtStreet;
    // }
    formData.page_name = 'AD_HDR_V2';
    // formData.pCode_manual = form['pCode_EntryType'].value;
    formData.txtPostCode = "";
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString', queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'AD_HDR_V2',
        queryString,
        visitorParameters.data
      )
      if (formSUbmitResult.data.status === "Success") {

        if (queryString != null) {
          console.log("first if");
          console.log(queryString);
          if (queryString.indexOf("&" + field + "=") !== -1) {
            console.log("s");
            if (EnvConstants.AppConversionPixel === 'true') {
              console.log("if fb");
              history.push("/fbpixel?split_name=" + props.splitName + "&next_page=" + props.nextPage); // fb fixel firing
            } else {
              console.log("else fb");
              window.scrollTo(0, 0);
              history.push(
                "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
              );
            }
          } else {
            console.log("another else fb");
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
            );
          }
        } else {

          console.log("laset else  fb");
          window.scrollTo(0, 0);
          history.push(
            "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
          );
        }
      }
    }
  };

  return (
    <>
      <section className="form-part" id="form-part">
        <div className="container nopad">
          <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
            <form autoComplete="off" action="otp_page.html">
              <ProgressBar
                progress={progress}
              />
              <LandingSlide
                showQuestionSlide={state.showLandingSlide}
                slideChange={slideChange}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide1}
                questionnaire={questinnare1}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide2}
                questionnaire={questinnare2}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionCheckboxSlide
                showQuestionSlide={state.showQuestionSlide3}
                questionnaire={questinnare3}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide4}
                questionnaire={questinnare4}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide5}
                questionnaire={questinnare5}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide6}
                questionnaire={questinnare6}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide7}
                questionnaire={questinnare7}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <QuestionRadioSlide
                showQuestionSlide={state.showQuestionSlide8}
                questionnaire={questinnare8}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                handleShowModal={props.handleShowModal}
                setModalBody={props.setModalBody}
                showLoadingModalForNextPage={true}
                setLoadingModal={props.setLoadingModal}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
              />
              <PersonalDetails
                showQuestionSlide={state.showQuestionSlide9}
                // questionnaire={questinnare5}
                previousSlide={previousSlide}
                slideChange={slideChange}
                backBtn={true}
                questionId={"form"}
                setInfoModalType={props.setInfoModalType}
                validation={register}
                validationMsg={errors}
                trigger={trigger}
                clearErrors={clearErrors}
                getValues={getValues}
                setError={setError}
                formSubmit={formSubmit}
              />
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default FormAD_HDR_V2