const unqualify = "Unfortunately, it appears based on this answer you do not qualify for compensation.";
const dont_need_the_repairs = "Sorry, we can't help you if you don't need the repairs to be completed.";
const own_property = "Sorry, if you live on your own property, we can't assist you.";
const rented_from_private_landlord = "Sorry, if you rented from a private landlord, we are unable to assist you.";
const not_iving_in_the_property = "Sorry, we can't assist you as you don't reside in the property";

const questinnare1 = {
  questionId: 2,
  question: "Who are you renting from?",
  answers: [
    {
      label: "Council Authority",
      value: "3"
    },
    {   
      label: "Housing Association",
      value: "4"
    },
    {
      label: "Private Landlord",
      value: "5",
      modalShow: rented_from_private_landlord,
    },
  ]
};
const questinnare2 = {
  questionId: 11,
  question: "Do you still need the repairs to be completed?",
  answers: [
    {
      label: "YES",
      value: "30"
    },
    {
      label: "NO",
      value: "31",
      modalShow: dont_need_the_repairs,
    },
  ]
};
const questinnare3 = {
  questionId: 4,
  question: "Please choose the problems below that you are experiencing in the property:",
  answers: [
    {
      label: "Major Leaks",
      value: "8"
    },
    {
      label: "Minor Leaks",
      value: "9"
    },
    {
      label: "Major Damp",
      value: "10"
    },
    {
      label: "Minor Damp",
      value: "11"
    },
    {
      label: "Cracked Walls / Ceiling",
      value: "12"
    },
    {
      label: "Broken / Rotten Windows",
      value: "13"
    },
    {
      label: "Roof or Chimney Issues",
      value: "14"
    },
    {
      label: "Other",
      value: "15"
    },

  ]
};
const questinnare4 = {
  questionId: 10,
  question: "Are you happy to proceed with a solicitor?",
  answers: [
    {
      label: "YES",
      value: "28"
    },
    {
      label: "NO",
      value: "29",
      modalShow: unqualify,
    },
  ]
};
const questinnare5 = {
  questionId: 5,
  question: "Have you complained to your landlord about these issues more than 6 months ago?",
  answers: [
    {
      label: "YES",
      value: "16"
    },
    {
      label: "NO",
      value: "17",
      modalShow: "Sorry, if you have not complained to your landlord about these issues then we will not be able to assist you.",
    }
  ]
};
const questinnare6 = {
  questionId: 3,
  question: "Are you still living in the property?",
  answers: [
    {
      label: "YES",
      value: "6"
    },
    {
      label: "NO",
      value: "7",
      modalShow: not_iving_in_the_property,
    }
  ]
};
const questinnare7 = {
  questionId: 9,
  question: "Have you cliamed previously?",
  answers: [
    {
      label: "NO",
      value: "27"
    },
    {
      label: "YES",
      value: "26",
      modalShow: "Sorry, if you cliamed previously, we will not be able to assist you.",
    }
  ]
};
const questinnare8 = {
  questionId: 7,
  question: "Are you currently behind on your rent?",
  answers: [
    {
      label: "NO",
      value: "20",
    },
    {
      label: "YES (I owe less than £1,500)",
      value: "21",
    },
    {
      label: "YES (I owe more than £1,500)",
      value: "22",
      modalShow: "Sorry, if you owe more than £1,500 then we will not be able to assist you.",
    },
  ]
};


export {
  questinnare1,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare8,
};
