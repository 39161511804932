const questinnare1   = {
    questionId:1,
    question : "Do you rent or own your property?",
    answers: [
            {
                label: "Rent",
                value: "1"
            },
            {
                label: "Own",
                value: "2"
            }
    ]
  };
  const questinnare2   = {
    questionId:2,
    question : "Who do you rent from?",
    answers: [
            {
                label: "Council",
                value: "3"
            },
            {
                label: "Housing Association",
                value: "4"
            },
            {
                label: "Private Landlord",
                value: "5"
            }
    ]
  };
  const questinnare3   = {
    questionId:3,
    question : "Are you still living in the property?",
    answers: [
            {
                label: "Yes",
                value: "6"
            },
            {
                label: "No",
                value: "7"
            }
    ]
  };
  const questinnare4   = {
    questionId:4,
    question : "Please choose the problems below that you are experiencing in the property:",
    answers: [
            {
                label: "Major Leaks",
                value: "8"
            },
            {
                label: "Minor Leaks",
                value: "9"
            },
            {
                label: "Major Damp",
                value: "10"
            },
            {
                label: "Minor Damp",
                value: "11"
            },
            {
                label: "Cracked Walls / Ceiling",
                value: "12"
            },
            {
                label: "Broken / Rotten Windows",
                value: "13"
            },
            {
                label: "Roof / Chimney Issues",
                value: "14"
            },
            {
                label: "Other",
                value: "15"
            },

    ]
  };
  const questinnare5   = {
    questionId:5,
    question : "Have you complained to your landlord about these issues?",
    answers: [
            {
                label: "Yes",
                value: "16"
            },
            {
                label: "No",
                value: "17"
            }
    ]
  };
  const questinnare6   = {
    questionId:6,
    question : "Has your landlord sufficiently addressed these issues within 6 months of complaint?",
    answers: [
            {
                label: "No",
                value: "18"
            },
            {
                label: "Yes",
                value: "19"
            }
    ]
  };
  const questinnare7   = {
    questionId:7,
    question : "Are you currently behind on your rent?",
    answers: [
            {
                label: "No",
                value: "20"
            },
            {
                label: "Yes (I owe less than £1,500)",
                value: "21"
            },
            {
                label: "Yes (I owe more than £1,500)",
                value: "22"
            },

    ]
  };
  const questinnare8   = {
    questionId:8,
    question : "Where do you live?",
    answers: [
            {
                label: "England",
                value: "23"
            },
            {
                label: "Wales",
                value: "24"
            },
            {
                label: "Other",
                value: "25"
            },

    ]
  };
  const questinnare9   = {
    questionId:9,
    question : "Have you cliamed previously?",
    answers: [

            {
                label: "No",
                value: "27"
            },
            {
                label: "Yes",
                value: "26"
            }
    ]
  };
  const questinnare10   = {
    questionId:10,
    question : "Are you happy to proceed with a solicitor?",
    answers: [
            {
                label: "Yes",
                value: "28"
            },
            {
                label: "No",
                value: "29"
            },
    ]
  };
  const questinnare11   = {
    questionId:11,
    question : "Do you still need the repairs to be completed?",
    answers: [
            {
                label: "Yes",
                value: "30"
            },
            {
                label: "No",
                value: "31"
            },
    ]
  };



  
  
  export { 
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5,
    questinnare6,
    questinnare7,
    questinnare8,
    questinnare9,
    questinnare10,
    questinnare11
  };
  