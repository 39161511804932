import React, { useContext, useState } from "react";
import { Salutation } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import  { DateOfBirth } from "../../../../Utility/DateOfBirth";
import InputButton from "../../../UI/InputButton";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import privacy from "../../../../assets/img/privacy.png";
import ContactDetails from "./ContactDetails";
const PersonalDetails = ({
   className,
   slideChange,
   validation,
   validationMsg,
   trigger,
   clearErrors,
   getValues,
   slideChangeAddress,
   splitForm,
   backClick,
   setError,
}) => {
   const form = splitForm.current;
   const question_3 = getValues();
  const [emailCheck, setEmailCheck] = useState();
  const [phoneCheck, setPhoneCheck] = useState();

   let bank = localStorage.getItem('bankName');
   const basicDetailsValidation = async (e) => {
      let errorFlag = 0;
      // var lstSalutationResult = await trigger("lstSalutation");

      var txtFNameResult = await trigger("txtFName");;
      var txtLNameResult;
      var emailVal;
      var phoneVal;
      var lstDobYearResult;
      // if (lstSalutationResult) {
      //    txtFNameResult = await trigger("txtFName");
      // }
      // else {
      //    document.getElementById("lstSalutation").focus();
      //    return false;
      // }
      if (txtFNameResult) {
         txtLNameResult = await trigger("txtLName");
      }
      else {
         document.getElementById("txtFName").focus();
         return false;
      }
      if (txtLNameResult) {
         emailVal = await trigger("txtEmail");
      }
      else {
         document.getElementById("txtLName").focus();
         return false;
      }
      if (emailVal && emailCheck == 1) {
         phoneVal = await trigger("txtPhone");
       } else {
         document.getElementById("txtEmail").focus();
         return false;
       }
       if (!phoneVal || phoneCheck == 0){
         document.getElementById("txtPhone").focus();
         setError("txtPhone", {
            type: "manual",
            message: "Invalid Phone",
          });
         return false;
       }
      // if (lstDobMonthResult) {
      //    lstDobYearResult = await trigger("DobYear");
      // }
      // else {
      //    document.getElementById("DobMonth").focus();
      //    return false;
      // }
      // if(lstDobYearResult)
      // {

      // }else{
      //    document.getElementById("DobYear").focus();
      // }
     

      if (
         // !lstSalutationResult ||
         !txtFNameResult ||
         !txtLNameResult ||
         !emailVal ||
         !phoneVal 
      ) {
         errorFlag = 1;
      }
      if (errorFlag === 0) {    
            slideChange(e,"question_10");    
      } else {
         return false;
      }
   };

   const namevalidate = async (e) => {
      if (e.target.value.trim() == "" || e.target.value.trim().length < 3){
         var name = (e.target.name == 'txtFName')? 'First Name' : 'Last Name';
        setError(e.target.name, {
          type: "manual",
          message: "Your " +name+ " is Invalid. Please Recheck",
        });
         if(e.target.value.trim().length == 0){
            e.target.value = e.target.value.trim();
         }
      } 
      return false;
    };

   
   return (
      
      <div id="slide-5" className={`slidetop_adjust top_minus  ${className}`}>
         <ProgressBar value="85"/>
         <h4>
                    What is the best way to contact you with an update about whether
                    you've won compensation?
                </h4>
         <div className="input-section px-3">    
            {/* <SelectBox
               className="form-select  mb-3"
               OptionValue={Salutation}
               name="lstSalutation"
               id="lstSalutation"
               onChange={() => clearErrors("lstSalutation")}
               clearErrors={clearErrors}
               myRef={validation({ required: "Please Select Title" })}
               validationMsg={
                  validationMsg.lstSalutation &&
                  validationMsg.lstSalutation.message
               }
            ></SelectBox> */}
            <div className="input-group ">
               <TextField
                  type="text"
                  className="form-control mb-3"
                  placeholder="First Name"
                  name="txtFName"
                  id="txtFName"
                  onBlur={namevalidate}
                  validation={validation({
                     required: "Please Enter First Name",
                     minLength: {
                        value: 3,
                        message: "Please Enter Valid First Name",
                     },
                     pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                     },
                  })}
                  
               ></TextField>
               {/* <span className="input-group-text privacyico">
                  <img src={privacy} alt="" />
               </span> */}
               {validationMsg.txtFName && (
                  <span
                     className="error_msg "
                     id="email_err"
                  >
                     {validationMsg.txtFName.message}
                  </span>
               )}
            </div>
            <div className="input-group ">
               <TextField
                  type="text"
                  className="form-control mb-3"
                  placeholder="Last Name"
                  name="txtLName"
                  id="txtLName"
                  onBlur={namevalidate}
                  validation={validation({
                     required: "Please Enter Last Name",
                     minLength: {
                        value: 3,
                        message: "Please Enter Valid Last Name",
                     },
                     pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                     },
                  })}
                  
               ></TextField>
               {/* <span className="input-group-text privacyico">
                  <img src={privacy} alt="" />
               </span>               */}
               {validationMsg.txtLName && (
                  <span
                     className="error_msg "
                     id="email_err"
                  >
                     {validationMsg.txtLName.message}
                  </span>
               )}           
            </div>        
            {/* <DateOfBirth
               textDob="Date Of Birth"
               validation={validation}
               validationMsg={validationMsg}
            /> */}
            <ContactDetails
            validation={validation}
            validationMsg={validationMsg}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={className}
            getValues={getValues}
            slideChange={slideChange}
            previousSlide={backClick}
            setEmailCheck={setEmailCheck}
            setPhoneCheck={setPhoneCheck}
            checkValidation={basicDetailsValidation}
            />
            {/* <InputButton
               name="five"
               className="btn next-bttn"
               id=""
               value="Next"
               btnType="button"
               onClick={basicDetailsValidation}
               style={{ float: "none" }}
            /> */}
         </div>
         {/* <p className="text-center">
            <span
               className="back04 back-btn"
               name="back04"
               children="<< Previous"
               onClick={backClick}
         ></span></p> */}
      </div>
   );
};
export default PersonalDetails;
