import React,{useState} from "react";
import logo from "../../assets/img/AD_HDR_V1/logo1_2.png";
import thumbs from "../../assets/img/AD_HDR_V1/thumbs.png";
import Footer from "../Includes/Layouts/AD_HDR_V2/Footer";
const ThankyouPage = () => {
  return (
    <>
      <div className="thankyoupage">
        <header className="">
          <div className="">
            <div className="text-center">
              <img src={logo} alt="" />
            </div>
          </div>
        </header>
        <div className="container ">
          <div className="row ">
            <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
              <div>
                <img src={thumbs} className="thumbsup_img" alt="" />
                <h1 className="animated zoomInUp">Thank you</h1>
                <p className="thankuparag">
                  You have successfully submitted your claim
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------- FOOTER SECTION STARTS HERE ---------------------  */}
        {/* ____________________Footer  - SECTION____________________ */}
        <Footer/>
      </div>

    </>
  );
};
export default ThankyouPage;