import React, { useState } from "react";
import arrow_bt from '../../../../assets/img/AD_HDR_V2/arrow_bt.png'
import downArrow from "../../../../assets/img/arrow-down.png";

const QuestionRadioSlide = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const handleRadioClick = (e, item) => {
    if (item.modalShow) {
      props.handleShowModal();
      props.setModalBody(item.modalShow);
    } else {
      if (props.showLoadingModalForNextPage) {
        props.setLoadingModal("show");
        setTimeout(() => {
          props.slideChange(e, radioName);
        }, 3000);
      } else {
        props.slideChange(e, radioName);
      }
    }
  };

  const answerBlock = props.questionnaire.answers.map((item, index) => {
    const name = 'question_' + question_id
    return (
      <div key={index} className="px-3">
        <input type="radio"
          id={'question_' + question_id + '_' + item.value}
          name={'question_' + question_id}
          value={item.value} data-answertext={item.label}
          data-question_id={question_id}
          // ref={props.validation({ required: "Please Select" })}
          onClick={(e) => handleRadioClick(e, item)}
          className={`radio-button next2`}
          ref= {props.validation}
        />
        <label className={`option_but label_class ${item.modalShow ? 'no-radio-active-css' : ''}`} htmlFor={'question_' + question_id + '_' + item.value}>
          <span>{item.label}</span>
        </label>
      </div>

    )
  })

  return (
    <>
      <div id={'slide' + question_id} className={`col-12 ${props.showQuestionSlide}`}>
        <div className="form-group px-3 py-3">
          <h4 className="title">{props.questionnaire.question}</h4>
          {answerBlock}
        </div>
        {props.backBtn &&
          <div className="col-lg-12 p-0" >
            <input
              type="button"
              className="btn back01"
              id={`back${question_id}`}
              name={`back${question_id}`}
              onClick={props.previousSlide}
              defaultValue="<< Back"
            />
          </div>}
        <p className="text-center px-4">
          <i className="fa-solid fa-lock mx-2" />
          We will handle your data in accordance with our
          <a className="text-underline" data-toggle="modal" data-target="#Privacy" onClick={() => props.setInfoModalType("privacyPolicy")}> Privacy Policy</a>
        </p>
      </div>
    </>
  )
};

export default QuestionRadioSlide;
