import React from "react";
import logo from "../../../../assets/img/AD_HDR_V1/logo1_2.png";
const Header = () =>{
    return(
        <>
        <div className="housing-disrepair"></div>
         <div className="housing-disrepair">
    {/* ____________________logo_sec -  SECTION____________________ */}
    {/* <section className="logo_sec">
      
    </section> */}
    {/* ____________________HEADER -  SECTION____________________ */}
    <header>
    <div className="container text-center logo_sec">
        <img className="logo_img1" src={logo} alt="" />
      </div>

      <div className="container">
        <div className="col-lg-6 offset-lg-3 col-12 text-center bg_l_dark">
          <h1>Is Your Rented Accommodation In Poor Condition?</h1>
          <h2>
            Force your landlord to fix it. You could get compensation worth
            £1,000s
          </h2>
        </div>
        <div className="col-12 col-lg-8 offset-lg-2">
          <ul className="d-md-flex justify-content-center">
            <li className="mx-2"> Quick And Easy </li>
            <li className="mx-2"> Thousands Affected </li>
            <li className="mx-2"> No-Win, No-Fee </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
        </>
    );
};
export default Header;