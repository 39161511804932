export const ModalQuestion= [ 
    {
        "17" : "Sorry, if you have not complained to your landlord about these issues then we will not be able to assist you.",
        "19" : "Sorry, if your landlord sufficiently addressed these issues within 6 months of complaint then we will not be able to assist you.",
        "22" : "Sorry, if you owe more than £1,500 then we will not be able to assist you.",
        "25" : "Sorry, if you do not reside in England or Wales, we will not be able to assist you.",
        "31" : "Sorry, we can't help you if you don't need the repairs to be completed.",
        "26" : "Sorry, if you cliamed previously, we will not be able to assist you.",
        "2"  : "Sorry, if you live on your own property, we can't assist you",
        "5"  : "Sorry, if you rented from a private landlord, we are unable to assist you.",
        "7"  : "Sorry, we can't assist you as you don't reside in the property",
        
    },
   

];