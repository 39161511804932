import React, { useEffect, useState } from 'react'
import loader_review2 from '../../../../assets/img/AD_HDR_V2/loader-review2.gif'

const LoadingModal = ({ showLoadingModal, setLoadingModal }) => {
  const [showH3First, setShowH3First] = useState(true);
  const [showH3Second, setShowH3Second] = useState(false);

  useEffect(() => {
    let timer1;
    let timer2;

    if (showLoadingModal === "show") {
      timer1 = setTimeout(() => {
        setShowH3First(false);
        setShowH3Second(true);
      }, 3000);

      timer2 = setTimeout(() => {
        setLoadingModal("hide");
        setShowH3Second(false);
        setShowH3First(true);
      }, 6000);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [showLoadingModal, setLoadingModal]);

  return (
    <>
      <div
        className={`modal modal_1 fade ${showLoadingModal}`}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        id="analyzemodal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div id="slide6k" className="col-12  text-center">
                <img src={loader_review2} alt="" />
                {showH3First && (
                  <h3 id="h3_first">Loading your responses</h3>
                )}
                {showH3Second && (
                  <div id="h3_second">
                    <h3> Great News! </h3>
                    <h4>
                      We have all of the information we need to work on getting you compensated!
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoadingModal